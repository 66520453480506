import { LOCALE_ID, NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClickOutsideModule } from 'ng-click-outside';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { JwtInterceptor } from './authentication/_helpers/jwt.interceptor';
import { ErrorInterceptor } from './authentication/_helpers/error.interceptor';
import { LayoutModule } from '@angular/cdk/layout';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AgmCoreModule } from '@agm/core';
import { CounterCardsComponent } from './helpers/components/counter-cards/counter-cards.component';
import { HomeModule } from './home/home.module';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { ChangePasswordComponent } from './layout/change-password/components/change-password.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CardsComponent } from './helpers/components/cards/cards.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';




const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    CounterCardsComponent,
    ChangePasswordComponent,
    CardsComponent
  ],

  exports: [CounterCardsComponent],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HomeModule,
    // core & shared
    CoreModule,
    SharedModule,
    MatDialogModule,
    Ng2SearchPipeModule,
    LayoutModule,
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA07ar2goufVwaYBbnMed8O0OVQest-oB8',
      libraries: ['places']
    })
  ],


  providers: [
    DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    
  ],

  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
