import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },

  {
    path: 'vendor-performance',
    loadChildren: () =>
      import('./vendor-performance/vendor-performance.module').then((m) => m.VendorPerformanceModule),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },


  {
    path: 'stores',
    loadChildren: () =>
      import('./stores/stores.module').then((m) => m.StoresModule),
  },

  {
    path: 'stores-ratings',
    loadChildren: () =>
      import('./store-rating/store-rating.module').then((m) => m.StoreRatingModule),
  },

  {
    path: 'offers',
    loadChildren: () =>
      import('./offers/offers.module').then((m) => m.OffersModule),
  },

  {
    path: 'analysis',
    loadChildren: () =>
      import('./analysis/analysis.module').then((m) => m.AnalysisModule),
  },

  {
    path: 'portfolio-analysis',
    loadChildren: () =>
      import('./portfolio-analysis/portfolio-analysis.module').then((m) => m.PortfolioAnalysisModule),
  },

  {
    path: 'accounts-management',
    loadChildren: () =>
      import('./accounts-management/accounts-management.module').then((m) => m.AccountsManagementModule),
  },

  {
    path: 'order-management',
    loadChildren: () =>
      import('./order-management/order-management.module').then((m) => m.OrderManagementModule),
  },

  {
    path: 'advertisements',
    loadChildren: () =>
      import('./advertisements/advertisements.module').then((m) => m.AdvertisementsModule),
  },
  {
    path: 'campaigns',
    loadChildren: () =>
      import('./campaigns/campaigns.module').then((m) => m.CampaignsModule),
  },
  {
    path: 'fare-scheme',
    loadChildren: () =>
      import('./fare-scheme/fare-scheme.module').then((m) => m.FareSchemeModule),
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
