import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-counter-cards',
  templateUrl: './counter-cards.component.html',
  styleUrls: ['./counter-cards.component.sass']
})
export class CounterCardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
