import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/services/global.service';

@Component({
  selector: 'app-counter-card',
  templateUrl: './counter-card.component.html',
  styleUrls: ['./counter-card.component.scss']
})
export class CounterCardComponent implements OnInit {

  @Input() cardTitle: string;
  @Input() subTitle: string;
  @Input() subline: string;
  @Input() count: number;
  @Input() loading: boolean;
  @Input() cardClass: string;

  title:string='';
  counter:number;
  isLoading:boolean;
  title2:string;
  line:string;
  
  constructor(private globalService:GlobalService) { }

  ngOnInit(): void {
  }
  ngOnChanges() :void {
    this.title=this.cardTitle;
    this.title2=this.subTitle;
    this.counter=this.count;
    this.isLoading=this.loading;
    this.line=this.subline;

  }
}
