import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/services/base-data.service';
import { AccountsManagers } from 'src/app/home/accounts-management/models/accounts-management.model';
import { DeliveryAreaList } from 'src/app/home/stores/models/looksup.model';
import { ZoneList } from 'src/app/home/stores/models/store.model';
import { BusinessChains, LooksupStoreList, StoresByChainID } from '../models/global.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  startDate: any;
  endDate: any;
  requestDateRange: string;

  private apiLooksupUrl = 'api/looksup/';

  constructor(
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private baseDataService: BaseDataService
  ) { }

  setDate(start:any, end:any){
    let startDate = this.datePipe.transform(start, 'yyyy/MM/dd');
    let endDate;

    if(end == null){
      endDate = this.datePipe.transform(start, 'yyyy/MM/dd')
    }else{
      endDate = this.datePipe.transform(end, 'yyyy/MM/dd');
    }

    return startDate + '-' + endDate;
  }



  setEndDate(end: any){
    let endDate = this.datePipe.transform(end, 'yyyy/MM/dd');
    return endDate;
  }


  getDateFormatted(start: any) {
    // let startDate = this.datePipe.transform(start, 'dd/MM/yyyy HH:mm:ss a');
    // return startDate;
    if(start.includes('Z')) {
      return moment(start.split('Z')[0]).format("DD/MM/YYYY HH:mm:ss a"); // final solution for time binding
    } else {
      return moment(start).format("DD/MM/YYYY HH:mm:ss a");
    }
  }

  getDateFormattedReports(start: any) {
    if(start) {
      // let startDate = this.datePipe.transform(start+'Z', 'dd/MM/yyyy HH:mm:ss a');
      // return startDate;
      if(start.includes('Z')) {
        return moment(start.split('Z')[0]).format("DD/MM/YYYY HH:mm:ss a"); // final solution for time binding
      } else {
        return moment(start).format("DD/MM/YYYY HH:mm:ss a");
      }
    }
  }


  setDateTime(date: any, time: any){
    let utcDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    // let utcTime = moment(time, 'hh:mm A').format('HH:mm');
    return utcDate+" "+time;
    // return utcDate + 'T' + utcTime + 'Z';
  }


  get12HoursTime(time: any){
    let utcTime = moment(time, 'HH:mm').format('hh:mm A')
    return utcTime;
  }


  setDateRequest(reqType: string){
    switch (reqType) {
      case 'Today':
        this.startDate = new Date();
        this.endDate = new Date();
        this.requestDateRange = this.setDate(this.startDate, this.endDate);
        break;

      case 'Week':
        this.startDate = moment().subtract( 1,'weeks').toDate();
        this.endDate = moment().subtract(1,'days').toDate();
        this.requestDateRange = this.setDate(this.startDate, this.endDate);
        break;

        case 'Month':
          this.endDate = moment().subtract(1,'days').toDate();
          this.startDate = moment().subtract(30,'days').toDate();

          // this.endDate= moment().toDate();
        this.requestDateRange = this.setDate(this.startDate, this.endDate);
        break;
    }
    return this.requestDateRange;
  }


  showSuccess(alertMassage: string) {
    this.toastr.success(alertMassage, 'Success!', {
      timeOut: 3000
    });
  }


  showError(alertMassage: string) {
    this.toastr.error(alertMassage, 'Error!', {
      timeOut: 3000
    });
  }


  getAbsoluteValue(num: number) {
    return Math.abs(num);
  }


  setSameHeight(cls) {
    var elements = document.getElementsByClassName(cls);

    equal_cols('.' + cls);
    function equal_cols(el) {
      var h = 0;
      $(el).each(function () {
        $(this).css({ 'height': 'auto' });

        if ($(this).outerHeight() > h) {
          h = $(this).outerHeight();
        }
      });

      $(el).each(function () {
        $(this).css({ 'height': h });
      });
    }

  }


  fixSingledate(date: string) {
    var retdate = date;
    var spt = date.split('-');
    if (spt[1] == 'null') {
      retdate = spt[0] + '-' + spt[0];
      // spt;
    }

    return retdate;
  }


  getUserDetails() {
    return {
      username: JSON.parse(localStorage.getItem('firstname')),
      isChainUser: JSON.parse(localStorage.getItem('isChainUser')),
      chainName: JSON.parse(localStorage.getItem('chainName')),
      storeName: JSON.parse(localStorage.getItem('storeName')),
      storeId: JSON.parse(localStorage.getItem('storeId')),
      chainId: JSON.parse(localStorage.getItem('chainId'))
    }
  }



  prependZero(n){
    return n > 9 ? "" + n: "0" + n;
  }


  getFullNumber(value:number){
    return parseInt(''+Math.round(value));
  }


  getThreeDecimalPlaces(number: number, place:number){
    let num: any = number?.toFixed(place)
    return num;
  }

  getValuesFormattedInReports(number: number, place:number){
    let num: any = number?.toFixed(place);
    return parseFloat(num).toLocaleString();
  }


  separateThousands(number: any){
    return parseInt(number).toLocaleString();
  }


  getStoreList(): Observable<LooksupStoreList[]>{
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getStoreList'}`);
  }
  getStoreListAccountManagement(): Observable<LooksupStoreList[]>{
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getAllStoreList'}`);
  }
  getStoreListOffers(): Observable<LooksupStoreList[]>{
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getStoreListOffers'}`);
  }


  getAccountManagers(): Observable<AccountsManagers[]>{
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getAccountManagers'}`);
  }


  getBusinessChainNamesOffers(): Observable<BusinessChains[]>{
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getBusinessChainNameOffers'}`);
  }
  getBusinessChainNames(): Observable<BusinessChains[]>{
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getBusinessChainName'}`);
  }


  getStoresByChainId(chainId: string): Observable<StoresByChainID[]>{
    let queryString = `chainId=${chainId}`
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getChainIdByStoreOffers'}?${queryString}`);
  }

  getStoresByChainIdReports(chainId: string): Observable<StoresByChainID[]>{
    let queryString = `chainId=${chainId}`
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getStoresByChainId'}?${queryString}`);
  }


  getZoneList():Observable<ZoneList[]>{
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getZones'}`);
  }


  getAreaZones(): Observable<DeliveryAreaList[]>{
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getAreaZones'}`)
  }


  getOperationalDays(){
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getOperationalDays'}`)
  }


  getOperationalTimes(){
    return this.baseDataService.makeGetCall(`${this.apiLooksupUrl}${'/getOperationalTimes'}`)
  }


  getObjArrToStr(items,key:string){
    var names = items.map(function(item) {
      return item[key];
    });

    return names.join(',')
  }


  getObjArrToArr(items,key:string){
    var names = items.map(function(item) {
      return item[key];
    });

    return names;
  }

}
