import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, ElementRef, OnInit, Renderer2, HostListener, OnDestroy, AfterViewInit} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { GlobalService } from 'src/app/helpers/services/global.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj: any = null;
  username: string;
  isChainUser: string;
  chainName: string;
  storeName: string;
  sectorCode: string;
  phoneNo:string;
  isLeadRole: string;
  roles: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private globalService: GlobalService,
  ) {
    const body = this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const role = ['admin', 'teacher', 'student'];
        const currenturl = event.url.split('?')[0];
        const firstString = currenturl.split('/').slice(1)[0];

        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = currenturl.split('/')[2];
          this.level2Menu = currenturl.split('/')[3];
        } else {
          this.level1Menu = currenturl.split('/')[1];
          this.level2Menu = currenturl.split('/')[2];
        }
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }

  @HostListener('window:resize', ['$event'])

  windowResizecall(event: any) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }


  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }


  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }


  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }


  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }


  ngOnInit() {
    this.getUserDetails();

    if (this.authService.currentUserValue) {
      this.roles =  JSON.parse(localStorage.getItem('roles'))

      console.log(this.roles);
      

      // this.isLeadRole =  JSON.parse(localStorage.getItem('isLeadRole'))
      // let userRole;
      // // if(this.roles.includes('KeyAccountManager')){
      // //   userRole = 'KeyAM'
      // // }else{
      // //   userRole = 'All'
      // // }

      // if(this.isLeadRole == 'True'){
      //   userRole = 'KeyAM'
      //  // console.log(userRole)
      // }else{
      //   userRole = 'All'
      //  // console.log(userRole)
      // }

console.log(this.roles)
      this.sidebarItems = ROUTES.filter(
        (x) => x.role.some(r=> this.roles.indexOf(r) !== -1) || x.role.some(r=> this.roles.indexOf('All') !== -1)
      );

      
      // this.sidebarItems = ROUTES.filter(
      //   (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf('All') !== -1
      // );

    }

    this.initLeftSidebar();
    this.bodyTag = this.document.body;

  }


  getUserDetails() {
    this.username = JSON.parse(localStorage.getItem('firstname'));
  }


  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }


  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }


  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }


  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }


  checkStatuForResize(firstTime: any) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }


  mouseHover(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }


  mouseOut(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }


  logout() {
    this.authService.logout();
  }


  ngAfterViewInit() {

  }
}
