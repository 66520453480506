import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  // Common Module

  {
    path: '',
    title: 'Main',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','KeyAccountManager','AccountManager'],
    submenu: [],
  },

  {
    path: 'home/dashboard',
    title: 'Dashboard',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','KeyAccountManager','AccountManager'],
    submenu: [],
  },

  {
    path: 'home/vendor-performance',
    title: 'Vendor Performance',
    moduleName: 'VendorPerformance',
    iconType: 'material-icons-two-tone',
    icon: 'insights',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','KeyAccountManager','AccountManager'],
    submenu: [],
  },

  {
    path: 'home/reports',
    title: 'Reports',
    moduleName: 'reports',
    iconType: 'material-icons-two-tone',
    icon: 'report',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','KeyAccountManager','AccountManager'],
    submenu: [],
  },

  {
    path: 'home/analysis',
      title: 'Analysis',
      moduleName: 'analysis',
      iconType: 'material-icons-two-tone',
      icon: 'insert_chart',
      class: '',
      groupTitle: false,
      badge: '',
      badgeClass: '',
      role: ['Administrator','AccountManagerLead','KeyAccountManager','AccountManager'],
      submenu: [],
  },

  {
    path: 'home/stores',
    title: 'Stores',
    moduleName: 'stores',
    iconType: 'material-icons-two-tone',
    icon: 'store',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','KeyAccountManager','AccountManager'],
    submenu: [],
  },
  {
    path: 'home/stores-ratings',
    title: 'Stores Ratings',
    moduleName: 'store-rating',
    iconType: 'material-icons-two-tone',
    icon: 'hotel_class',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','KeyAccountManager','AccountManager'],
    submenu: [],
  },
  {
    path: 'home/offers',
    title: 'Offers',
    moduleName: 'offers',
    iconType: 'material-icons-two-tone',
    icon: 'card_giftcard',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Marketing','MarketingSenior'],
    submenu: [
      // {
      //   path: 'home/offers/offer-list',
      //   title: 'Target Audience Offers',
      //   moduleName: 'offer-list',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: [''],
      //   submenu: [],
      // },

      {
        path: 'home/offers/offer-queries',
        title: 'Target Audience Builder',
        moduleName: 'offer-queries',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },

  {
    path: 'home/order-management',
    title: 'Order Management',
    moduleName: 'accounts-management',
    iconType: 'material-icons-two-tone',
    icon: 'receipt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','AccountManager','KeyAccountManager'],
    submenu: [],
  },

  {
    path: 'home/portfolio-analysis',
    title: 'Portfolio Analysis',
    moduleName: 'portfolio-analysis',
    iconType: 'material-icons-two-tone',
    icon: 'multiline_chart',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','KeyAccountManager'],
    submenu: [],
  },

  {
    path: 'home/accounts-management',
    title: 'Accounts Management',
    moduleName: 'accounts-management',
    iconType: 'material-icons-two-tone',
    icon: 'manage_accounts',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','AccountManagerLead','KeyAccountManager'],
    submenu: [],
  },
  {
    path: 'home/campaigns',
    title: 'Campaigns',
    moduleName: 'campaigns',
    iconType: 'material-icons-two-tone',
    icon: 'campaign',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Administrator','Marketing','MarketingSenior'],
    submenu: [],
  },
//   {path: 'home/fare-scheme/driver-fare-scheme-list',
//   title: 'Slab Scheme',
//   moduleName: 'fare-scheme',
//   iconType: 'material-icons-two-tone',
//   icon: 'paid',
//   class: 'menu-toggle',
//   groupTitle: false,
//   badge: '',
//   badgeClass: '',
//   role: ['Marketing','MarketingSenior'],
//   submenu: [
//     {
//       path: 'home/fare-scheme/driver-fare-scheme-list',
//       title: 'Delivery Slab Scheme',
//       moduleName: 'driver-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/profitability-fare-scheme-list',
//       title: 'Profitability Slab scheme',
//       moduleName: 'profitability-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/order-count-weight-fare-scheme',
//       title: 'Order Count Weight Slab Scheme ',
//       moduleName: 'order-count-weight-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/driver-availability-store-radius-fare-scheme',
//       title: 'Driver Availability Store Radius Slab Scheme',
//       moduleName: 'driver-availability-store-radius-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/ready-order-store-radius-fare-scheme',
//       title: 'Ready Order Store Radius Slab Scheme',
//       moduleName: 'ready-order-store-radius-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/speed-of-delivery-fare-scheme',
//       title: 'Speed of Delivery Slab Scheme',
//       moduleName: 'speed-of-delivery-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/redispatch-rate-fare-scheme',
//       title: 'Redispatch Rate Slab Scheme',
//       moduleName: 'redispatch-rate-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/average-attendance-fare-scheme',
//       title: 'Average Attendance Slab Scheme',
//       moduleName: 'average-attendance-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/day-part-weight-fare-scheme',
//       title: 'Day Part Weight Slab Scheme',
//       moduleName: 'day-part-weight-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/block-count-fare-scheme',
//       title: 'Block Count Slab Scheme',
//       moduleName: 'block-count-fare-scheme',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//     {
//       path: 'home/fare-scheme/number-of-fines',
//       title: 'Number of Fines Slab Scheme',
//       moduleName: 'number-of-fines',
//       iconType: '',
//       icon: '',
//       class: 'ml-menu',
//       groupTitle: false,
//       badge: '',
//       badgeClass: '',
//       role: [''],
//       submenu: [],
//     },
//   ],
// }



  // {
  //   path: 'home/accounts-management',
  //   title: 'Accounts Management',
  //   moduleName: 'accounts-management',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'manage_accounts',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['AccountManager'],
  //   submenu: [],
  // },
  // {
  //   path: 'home/advertisements',
  //   title: 'Advertisements',
  //   moduleName: 'adevertisements',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'ad_units',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['AccountManager'],
  //   submenu: [
  //     {
  //       path: 'home/advertisements/banners',
  //       title: 'Banners',
  //       moduleName: 'banners',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/notifications',
  //       title: 'Notifications',
  //       moduleName: 'notifications',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/collections',
  //       title: 'Collections',
  //       moduleName: 'collections',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/promocodes',
  //       title: 'Promocodes',
  //       moduleName: 'promocodes',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/specialAttributes',
  //       title: 'Special Attributes',
  //       moduleName: 'specialAttributes',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/specialGreetings',
  //       title: 'Special Greetings',
  //       moduleName: 'specialGreetings',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/zoneFeaturedStore',
  //       title: 'Zone Featured Stores',
  //       moduleName: 'zoneFeaturedStore',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/areaFeaturedStore',
  //       title: 'Area Featured Stores',
  //       moduleName: 'areaFeaturedStore',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/freeDeliveryZones',
  //       title: 'Free Delivery (Zones)',
  //       moduleName: 'freeDeliveryZones',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     },
  //     {
  //       path: 'home/advertisements/freeDeliveryAreas',
  //       title: 'Free Delivery (Areas)',
  //       moduleName: 'freeDeliveryAreas',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'report',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['AccountManager'],
  //       submenu: [],
  //     }

  //   ],
  // },


];
