import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HomeRoutingModule } from './home-routing.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LoaderComponent } from './loader/loader.component';
import { LayoutModule } from '@angular/cdk/layout';
import { CounterCardComponent } from './counter-card/counter-card.component';
import {MatChipsModule} from '@angular/material/chips';




@NgModule({
  declarations: [
    LoaderComponent,
    CounterCardComponent,
  ],

  imports: [
    CommonModule,
    HomeRoutingModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    NgxSpinnerModule,
    LayoutModule,
    MatChipsModule
  ],

  exports:[LoaderComponent,CounterCardComponent]
})
export class HomeModule { }
