<div class="plain-card same" [ngClass]="cardClass">
    <div class="card-statistic-4">
      <div class="align-items-center justify-content-between">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h3 class="font-20"><i class="far fa-list-alt"></i> {{title}}</h3>
            <hr />
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 card-spacing">
            <app-loader *ngIf="isLoading"></app-loader>
            <div class="card-content" *ngIf="!isLoading">
              <div>
                <p class="font-18 card-title"> {{line}}</p>
              </div>

              <div>
                <h1 class="card-amount-values mt-3 font-50">{{globalService.prependZero(counter)}}</h1>
              </div>
              <div class="banner-img">
                <h4 class="card-title mt-3 font-20">{{title2}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
