import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/services/global.service';


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})
export class MainLayoutComponent implements OnInit {
  constructor(private globalService : GlobalService) {}

  ngOnInit(): void { }

  getStoreList() {



 }
}
